<div class="widget-block widget-grey-bg" style="margin-top: 10px">
  <div class="row">
    <div
      class="col-md-12 d-flex justify-content-center align-items-center text-center"
      style="min-height: 400px">
      <div>
        <img
          [src]="imageURL"
          [alt]="title | translate"
          class="img-fluid d-block mx-auto mb-3"/>

        <h5 class="font-lg">{{ title | translate }}</h5>

        <p>
          {{ description | translate }}
          @if(isAddButton){
          <br />
          {{ subTitle | translate}}
        }
        </p>
        @if(isAddButton){
        <app-add-new-button
          [label]="addButtonLabel"
          (addRequest)="requestAdd()">
        </app-add-new-button>
        }
      </div>
    </div>
  </div>
</div>
